<template>
<div class="container">
  <page-title title="Payment Channels"/>
  
  <div class="row border bg-light py-2 mb-2">
    <input-select v-model="filterCurrency" group-class="col-auto">
      <option value="ALL">All Currencies</option>
      <option v-for="cur in currencies" :key="cur.refCode" :value="cur.refCode">{{cur.refCode}} - {{cur.refValue}}</option>
    </input-select>
  </div>
  
  <div class="row">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Channel Code</th>
          <th>Currency</th>
          <th>Pay-In</th>
          <th>Pay-Out</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="ch in channels">
        <tr v-if="filterCurrency === 'ALL' || ch.currency === filterCurrency" :key="ch.channelCode" @click.prevent="showDetails(ch)" style="cursor: pointer;">
          <td>{{ch.channelCode}}</td>
          <td>{{ch.currency}}</td>
          <td class="fw-bold text-center" :class="[ch.payinSpecs.enabled ? 'text-success' : 'text-warning']">
            <i v-if="ch.payinSpecs.enabled" class="fa-solid fa-check fa-lg"></i>
            <i v-else class="fa-solid fa-xmark fa-lg"></i>
          </td>
          <td class="fw-bold text-center" :class="[ch.payoutSpecs.enabled ? 'text-success' : 'text-warning']">
            <i v-if="ch.payoutSpecs.enabled" class="fa-solid fa-check fa-lg"></i>
            <i v-else class="fa-solid fa-xmark fa-lg"></i>
          </td>
        </tr>
        </template>
      </tbody>
    </table>
  </div>
  
  <b-modal id="ch-details" title="Payment Channel" hide-footer size="lg">
    <payment-channel-details :channel="selectedChannel">
    </payment-channel-details>
  </b-modal>
 
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import PaymentChannelDetails from '../components/PaymentChannelDetails.vue'
export default {
  name: 'PaymentChannels',
  mixins: [HelpersMixin],
  components: { PaymentChannelDetails },
  data () {
    return {
      channels: [],
      selectedChannel: null,
      filterCurrency: 'ALL',
      currencies: []
    }
  },
  mounted () {
    this.getCurrencies()
    this.getChannels()
  },
  methods: {
    getChannels: function () {
      this.axios.get('/mx/channels')
      .then(response => {
        this.channels = response.data
        this.channels.sort((a,b) => {
          if (a.channelCode < b.channelCode) {
            return -1
          }
          if (a.channelCode > b.channelCode) {
            return 1
          }
          return 0
        })
      })
      .catch(error => this.handleError(error))
    },
    showDetails: function (channel) {
      this.selectedChannel = channel
      this.$bvModal.show('ch-details')
    },
    getCurrencies: function () {
      this.axios.get('/mx/currencies')
      .then(response => {
        this.currencies = response.data
      })
      .catch(error => this.handleError(error))
    }
  }
}
</script>