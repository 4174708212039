<template>
<span>
  <template v-if="value">{{value | moment("timezone", tz, format)}}</template>
  <template v-else>--</template>
</span>
</template>

<script>
export default {
  name: 'DisplayDate',
  props: {
    value: [String, Number, Date, Object], 
    timezone: String, 
    hideyear: { type: Boolean, default: false }
    },
  data () {
    return {
      formatShort: 'MMM DD - HH:mm zz',
      formatDefault: 'YYYY-MM-DD HH:mm zz'
    }
  },
  computed: {
    tz: function () {
      if (this.timezone) {
        return this.timezone
      } else {
        return this.$localtz
      }
    },
    format: function () {
      if (this.hideyear) {
        return this.formatShort
      } else {
        return this.formatDefault
      }
    }
  }
}
</script>