import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment-timezone'
import Toasted from 'vue-toasted'
import VueAxios from 'vue-axios'
import Vue2Filters from 'vue2-filters'
import VueMoment from 'vue-moment'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import PortalHeader from './components/PortalHeader.vue'
import PageTitle from './components/PageTitle.vue'
import InputText from './components/InputText.vue'
import InputDate from './components/InputDate.vue'
import InputNumber from './components/InputNumber.vue'
import InputSelect from './components/InputSelect.vue'
import DisplayField from './components/DisplayField.vue'
import DisplayAmount from './components/DisplayAmount.vue'
import DisplayAmountObj from './components/DisplayAmountObj.vue'
import DisplayDate from './components/DisplayDate.vue'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios;
Vue.use(VueMoment, {
  moment
})
Vue.use(Vue2Filters)
Vue.use(BootstrapVue)
Vue.use(Toasted, {
  theme: 'bubble',
  position: 'top-right',
  duration: 5000,
  fullWidth: false,
  closeOnSwipe: true,
  iconPack: 'fontawesome',
  action: [
    {
      icon: 'fa-times-circle',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      }
    }
  ]
})

Vue.component('portal-header', PortalHeader)
Vue.component('page-title', PageTitle)
Vue.component('input-text', InputText)
Vue.component('input-date', InputDate)
Vue.component('input-number', InputNumber)
Vue.component('input-select', InputSelect)
Vue.component('display-field', DisplayField)
Vue.component('display-amount', DisplayAmount)
Vue.component('display-amount-obj', DisplayAmountObj)
Vue.component('display-date', DisplayDate)

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error.response.data)
  if (error.response.status === 401) {
    store.commit('clearToken')
    router.push('/login').catch(() => {})
  }
  return Promise.reject(error)
})

axios.interceptors.request.use(
  config => {
    config.headers['X-paymesh-mxauthz'] = store.state.token.tokenId
    return config
  },
  error => { Promise.reject(error) }
)

const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone
Vue.prototype.$localtz = localtz
Vue.prototype.$eventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
