<template>
<div class="container">
  <div class="row">
    <display-field label="Channel Code" group-class="col-auto fw-bold">{{ch.channelCode}}</display-field>
    <display-field label="Currency" group-class="col-auto fw-bold">{{ch.currency}}</display-field>
  </div>
  
  <div class="row text-white border py-2" :class="[ch.payinSpecs.enabled ? 'bg-success' : 'bg-warning']">
    <div class="col-auto small fst-italic">Pay-In Specifications</div>
    <div v-if="ch.payinSpecs.enabled" class="col-auto ms-auto fw-bold">Status: ENABLED</div>
    <div v-else class="col-auto ms-auto fw-bold">Status: NOT AVAILABLE</div>
  </div>
  <div v-if="ch.payinSpecs.enabled" class="row border py-2">
    <div class="row">
      <display-field label="Minimum Amount" group-class="col-auto">
        <display-amount :value="ch.payinSpecs.minTxnAmount"/>
      </display-field>
      <display-field label="Maximum Amount" group-class="col-auto">
        <display-amount :value="ch.payinSpecs.maxTxnAmount"/>
      </display-field>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <p class="mb-1 small text-info">Required "endUserInfo" Fields</p>
        <p v-if="!ch.payinSpecs.requiredEndUserInfo || ch.payinSpecs.requiredEndUserInfo.length === 0" class="ps-3 small fw-bold">--NONE--</p>
        <ul class="list-group">
          <li v-for="(piFld, idx) in ch.payinSpecs.requiredEndUserInfo" :key="idx" class="list-group-item">
            {{piFld.fieldName}} - 
            <span class="small text-warning fst-italic">{{piFld.description}}</span>
          </li>
        </ul>
      </div>
      <div class="col-6">
        <p class="mb-1 small text-info">Required "payInfo" Fields</p>
        <p v-if="!ch.payinSpecs.requiredPayInfo || ch.payinSpecs.requiredPayInfo.length === 0" class="ps-3 small fw-bold">--NONE--</p>
        <ul class="list-group">
          <li v-for="(piFld, idx) in ch.payinSpecs.requiredPayInfo" :key="idx" class="list-group-item">
            {{piFld.fieldName}} - 
            <span class="small text-warning fst-italic">{{piFld.description}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="ch.payinSpecs.bankCodeRequired" class="col-12">
      <a href="#" @click.prevent="showBanks()" class="text-info text-decoration-none small"><i class="fa-solid fa-building-columns me-2"></i>Available Banks</a>
    </div>
  </div>
  
  <div class="row text-white border mt-3 py-2" :class="[ch.payoutSpecs.enabled ? 'bg-success' : 'bg-warning']">
    <div class="col-auto small fst-italic">Pay-Out Specifications</div>
    <div v-if="ch.payoutSpecs.enabled" class="col-auto ms-auto fw-bold">Status: ENABLED</div>
    <div v-else class="col-auto ms-auto fw-bold">Status:  NOT AVAILABLE</div>
  </div>
  <div v-if="ch.payoutSpecs.enabled" class="row border py-2">
    <div class="row">
      <display-field label="Minimum Amount" group-class="col-auto">
        <display-amount :value="ch.payoutSpecs.minTxnAmount"/>
      </display-field>
      <display-field label="Maximum Amount" group-class="col-auto">
        <display-amount :value="ch.payoutSpecs.maxTxnAmount"/>
      </display-field>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <p class="mb-1 small text-info">Required "endUserInfo" Fields</p>
        <p v-if="!ch.payoutSpecs.requiredEndUserInfo || ch.payoutSpecs.requiredEndUserInfo.length === 0" class="ps-3 small fw-bold">--NONE--</p>
        <ul class="list-group">
          <li v-for="(piFld, idx) in ch.payoutSpecs.requiredEndUserInfo" :key="idx" class="list-group-item">
            {{piFld.fieldName}} - 
            <span class="small text-warning fst-italic">{{piFld.description}}</span>
          </li>
        </ul>
      </div>
      <div class="col-6">
        <p class="mb-1 small text-info">Required "payInfo" Fields</p>
        <p v-if="!ch.payoutSpecs.requiredPayInfo || ch.payoutSpecs.requiredPayInfo.length === 0" class="ps-3 small fw-bold">--NONE--</p>
        <ul class="list-group">
          <li v-for="(piFld, idx) in ch.payoutSpecs.requiredPayInfo" :key="idx" class="list-group-item">
            {{piFld.fieldName}} - 
            <span class="small text-warning fst-italic">{{piFld.description}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="ch.payoutSpecs.bankCodeRequired" class="col-12">
      <a href="#" @click.prevent="showBanks()" class="text-info text-decoration-none small"><i class="fa-solid fa-building-columns me-2"></i>Available Banks</a>
    </div>
  </div>

  <b-modal id="ch-banks" title="Available Banks" hide-footer size="lg">
    <payment-channel-banks :channelCode="channel.channelCode"></payment-channel-banks>
  </b-modal>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import PaymentChannelBanks from '../components/PaymentChannelBanks.vue'
export default {
  name: 'PaymentChannelDetails',
  mixins: [HelpersMixin],
  props: ['channel'],
  components: { PaymentChannelBanks },
  data () {
    return {
      ch: { payinSpecs: {}, payoutSpecs: {} }
    }
  },
  mounted () {
    this.ch = this.channel
  },
  methods: {
    showBanks: function () {
      this.$bvModal.show('ch-banks')
    }
  }
}
</script>