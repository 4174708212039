<template>
<span>
  <span v-if="integerPart === 0 && value < 0">-</span>
  <span :class="{lighter : isLessThanOne}">{{integerPart | number('0,0')}}.</span>
  <span :class="[unisize ? '' : 'smaller', isLessThanOne && fractionalPart !== '00' ? 'darker' : 'lighter']">{{fractionalPart}}</span>
  <span v-if="currency" class="smaller"> {{currency}}</span>
</span>
</template>

<script>
export default {
  name: 'DisplayAmount',
  props: {
    value: [String, Number],
    currency: String,
    unisize: { type: Boolean, default: false },
    scale: { type: Number, default: 2 }
  },
  computed: {
    integerPart: function () {
      if (!this.value || isNaN(this.value)) {
        return 0;
      }
      return Math.trunc(this.value)
    },
    fractionalPart: function () {
      if (!this.value || isNaN(this.value)) {
        return ''.padStart(this.scale, '0');
      }
      const x = Math.abs( Math.round( (this.value - this.integerPart) * 100 ) )
      return (String(x).padStart(this.scale, '0'))
    },
    isLessThanOne: function () {
      if (!this.value || isNaN(this.value)) {
        return true
      }
      if (this.value < 1 && this.value > 0) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.lighter { font-weight: lighter;}
.smaller { font-size: 75%; }
.darker { font-weight: 500; }
</style>
