<template>
<div class="row mt-5 justify-content-center">
  <div class="col-md-5">
    <div class="alert alert-danger" v-if="error.hasError">{{error.errorMsg}}</div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="onLogin" action="">
          <div class="form-group">
            <label>Merchant ID or Alias</label>
            <input type="text"
              class="form-control" name="merchantId"
              v-model="login.merchantId" />
          </div>
          <div class="form-group">
            <label>User ID</label>
            <input type="text"
              class="form-control" name="userId" v-model="login.userId" />
          </div>
          <div class="form-group">
            <label>Password</label>
            <input type="password"
              class="form-control" name="loginPass"
              v-model="login.loginPass" />
          </div>
          <div class="text-end mt-3">
            <button type="submit" class="btn btn-primary">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'MerchantLogin',
  data () {
    return {
      login: { merchantId: '', userId: '', loginPass: '' },
      error: { hasError: false, errorMsg: '' }
    }
  },
  methods: {
    onLogin: function () {
      this.error.hasError = false
      this.error.errorMsg = ''
      this.axios.post('/mx/login', this.login)
        .then(response => {
          this.$store.commit('saveToken', response.data)
          this.$store.commit('clearSearchParams')
          this.$router.push('/dashboard')
          this.$eventBus.$emit('logged-in')
        })
        .catch(error => {
          console.log(error.response.status + ': ' + error.response.data)
          this.error.hasError = true
          this.error.errorMsg = 'Invalid Login Credentials'
        })
    }
  }
}
</script>

<style scoped>
label {
  margin-bottom: .1em;
  font-size: .75em;
  font-weight: bold;
}
</style>
