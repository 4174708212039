<template>
<div class="container-fluid">
  <div class="row mb-3">
    <display-field label="Transaction ID" group-class="col-auto fw-bold">{{txn.txnId}}</display-field>
    <display-field label="Channel Code" group-class="col-auto fw-bold">{{txn.channelCode}}</display-field>
    <div v-if="txn.txnType === 'BD_PAY_IN'" class="col-auto ms-auto fw-bold text-white bg-success my-1 py-3">PAY-IN</div>
    <div v-if="txn.txnType === 'BD_PAY_OUT'" class="col-auto ms-auto fw-bold text-white bg-danger my-1 py-3">PAY-OUT</div>
  </div>
  <div class="row">
    <display-field label="Status" group-class="col-6 fw-bold">{{txn.currentStatus}}
      <p v-if="txn.currentStatus === 'FORAUTHZ' || txn.currentStatus === 'PENDING'" class="mb-0 pb-0 small">
        <button class="btn btn-danger btn-sm" @click.prevent="showCancelForm">Cancel Transaction</button>
      </p>
    </display-field>
    <display-field label="Transaction Result" group-class="col-6">
      <span class="fw-bold">{{txn.txnResultCode}}</span><br/>
      <small>{{txn.txnResultMesssage}}</small>
    </display-field>
  </div>

  <div class="row">
    <display-field label="Requested Amount" group-class="col-4 fw-bold"><display-amount-obj :value="txn.txnAmount"/></display-field>
    <display-field label="Processed Amount" group-class="col-4 fw-bold"><display-amount-obj :value="txn.processedAmount"/></display-field>
    <display-field label="Transaction Fee" group-class="col-4 fw-bold"><display-amount-obj :value="txn.txnFee"/></display-field>
  </div>

  <div class="row">
    <display-field label="Request Date" group-class="col-6">
      <display-date :value="txn.createDate" timezone="UTC"/>
      <span class="pl-2 text-secondary small"><br/><display-date :value="txn.createDate"/></span>
    </display-field>
    <display-field label="Status Date" group-class="col-6">
      <display-date :value="txn.currentStatusDate" timezone="UTC"/>
      <span class="pl-2 text-secondary small"><br/><display-date :value="txn.currentStatusDate"/></span>
    </display-field>
  </div>

  <div class="row">
    <display-field label="Merchant ID" group-class="col-6">{{txn.merchantId}}</display-field>
    <display-field label="Account ID" group-class="col-6">{{txn.accountId}}</display-field>
  </div>
  <div class="row">
    <display-field label="Merchant Name">{{txn.merchantName}}</display-field>
  </div>
  <div class="row">
    <display-field label="Merchant Payment Reference" group-class="col-12">{{txn.paymentRef}}</display-field>
  </div>
  <div class="row">
    <display-field label="Merchant Trace Data" group-class="col-12">{{txn.merchantTraceData}}</display-field>
    <display-field label="Result URL">{{txn.resultUrl}}</display-field>
  </div>
  
  <div class="row bg-secondary text-info">
    <div class="col small fst-italic border border-info">End-User Info</div>
  </div>
  <div class="row border border-info pt-2">
    <display-field label="End-User Identifier" group-class="col-6"><span v-if="txn.endUserInfo">{{txn.endUserInfo.identifier}}</span></display-field>
    <display-field label="User IP Address" group-class="col-6"><span v-if="txn.endUserInfo">{{txn.endUserInfo.ipAddress}}</span></display-field>
    <display-field label="Phone Number" group-class="col-6"><span v-if="txn.endUserInfo">{{txn.endUserInfo.phoneNumber}}</span></display-field>
    <display-field label="Email Address" group-class="col-6"><span v-if="txn.endUserInfo">{{txn.endUserInfo.emailAddress}}</span></display-field>
    <display-field label="End-User Name" group-class="col-12"><span v-if="txn.endUserInfo">{{txn.endUserInfo.firstName}} {{txn.endUserInfo.lastName}}</span></display-field>
    <display-field label="Address" group-class="col-12">
      <span v-if="txn.endUserInfo && txn.endUserInfo.address">
        {{txn.endUserInfo.address.addressLine1}} {{txn.endUserInfo.address.addressLine2}}<br/>
        {{txn.endUserInfo.address.cityTown}} {{txn.endUserInfo.address.stateProv}}<br/>
        {{txn.endUserInfo.address.country}} {{txn.endUserInfo.address.postalCode}}
      </span>
      <span v-else>--</span>
    </display-field>
  </div>

  <div class="row bg-secondary text-info mt-2" v-if="txn.paymentInfo">
    <div class="col small fst-italic border border-info">Payment Info</div>
  </div>
  <div class="row border border-info pt-2" v-if="txn.paymentInfo">
    <div class="col">
      <ul>
        <template v-for="(value, key, index) in txn.paymentInfo">
        <li v-if="key !== 'procBankCode'" :key="index">{{key}} = {{value}}</li>
        </template>
      </ul>
    </div>
  </div>

  <div class="row" v-if="txn.comments">
    <display-field label="Comments">
      <p v-for="(comment, index) in txn.comments" :key="index" class="small ms-0">{{comment}}</p>
    </display-field>
  </div>
  
  <b-modal id="cancel-form" title="Cancel Transaction" size="lg" hide-footer no-close-on-backdrop>
    <div class="row">
      <p class="col-auto mx-auto alert alert-danger">Confirm cancellation of transaction {{txnId}}</p>
    </div>
    <div class="row">
      <textarea v-model="cancelComment" rows="3" class="col-auto form-control" placeholder="Enter comments (optional)"></textarea>
    </div>
    <div class="row mt-3">
      <div class="col-auto ms-auto">
        <template v-if="cancelInProgress">
          <b-spinner variant='primary' label='Please wait'></b-spinner>
          <span class="ms-3 mt-1">Processing ... Please wait</span>
        </template>
        <template v-else>
          <button class="btn btn-secondary me-2" @click.prevent="abortCancellation">Abort Cancellation</button>
          <button class="btn btn-primary" @click.self.prevent="processCancellation">Proceed with Cancellation</button>
        </template>
      </div>
    </div>
  </b-modal>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'TxnDetails',
  mixins: [HelpersMixin],
  props: [ 'txnId' ],
  data () {
    return {
      txn: { createDate: {}, statusDate: {}, txnAmount: {value: 0} },
      cancelComment: '',
      cancelInProgress: false
    }
  },
  mounted () {
    this.getTxnDetails()
  },
  methods: {
    getTxnDetails: function () {
      this.axios.get('/mx/txn/' + this.txnId)
      .then(result => {
        this.txn = result.data
      })
      .catch(error => this.handleError(error))
    },
    showCancelForm: function () {
      this.cancelInProgress = false
      this.$bvModal.show('cancel-form')
    },
    abortCancellation: function () {
      this.$bvModal.hide('cancel-form')
    },
    processCancellation: function () {
      this.cancelInProgress = true
      this.axios.post('/mx/txn/cancel', {txnId: this.txnId, comment: this.cancelComment})
      .then(result => {
        this.txn = result.data
        this.$toasted.success('Transaction ' + this.txnId + ' cancelled')
        this.$bvModal.hide('cancel-form')
        this.$emit('dtlChanged', this.txn)
      })
      .catch(error => { 
        this.cancelInProgress = false
        this.handleError(error) 
      })
    }
  }
}
</script>