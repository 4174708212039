<template>
<div class="container">
  <h5 class="text-center">Change Password</h5>
  <div class="row justify-content-center mt-5">
    <div class="text-center alert alert-success" v-if="changeSuccess">
      Your password has been successfully changed
    </div>
    <div class="col-md-6" v-else>
      <div class="alert alert-danger" v-if="error.hasError">{{error.errorMsg}}</div>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="onSubmit" action="">
            <div class="form-group">
              <label>Current Password</label>
              <input type="password" class="form-control" v-model="formData.currentPw"/>
            </div>
            <div class="form-group">
              <label>New Password</label>
              <input type="password" class="form-control" v-model="formData.newPw1"/>
            </div>
            <div class="form-group">
              <label>Retype New Password</label>
              <input type="password" class="form-control" v-model="formData.newPw2"/>
            </div>
            <div class="text-end mt-3">
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'PwChange',
  data () {
    return {
      formData: { currentPw: '', newPw1: '', newPw2: '' },
      error: { hasError: false, errorMsg: '' },
      changeSuccess: false
    }
  },
  methods: {
    onSubmit: function () {
      this.error.hasError = false
      this.error.errorMsg = ''
      this.axios.post('/mx/submitpwchange', this.formData)
        .then(response => {
          console.log(response.data)
          this.formData = { currentPw: '', newPw1: '', newPw2: '' }
          this.changeSuccess = true
        })
        .catch(error => {
          console.log(error.response.status + ': ' + error.response.data)
          this.error.hasError = true
          this.error.errorMsg = error.response.data.msgText
        })
    }
  }
}
</script>

<style scoped>
label { font-size: 75%; margin-bottom: 1px; font-weight: 600; }
</style>
