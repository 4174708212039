import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: JSON.parse(sessionStorage.getItem('mxtoken') || '{}'),
    mxList: null,
    mxLookup: {}
  },
  mutations: {
    saveToken (state, t) {
      sessionStorage.setItem('mxtoken', JSON.stringify(t))
      state.token = t
    },
    clearToken (state) {
      sessionStorage.removeItem('mxtoken')
      state.token = {}
    },
    saveSearchParams (state, p) {
      state.searchParams = p
    },
    clearSearchParams (state) {
      state.searchParams = null
    }
  },
  actions: {

  }
})
