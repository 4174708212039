<template>
<div class="container">
  <page-title title="Balances"/>

  <div class="row mb-3">
    <div class="col-auto">
      <period-selector @periodUpdated="onPeriodUpdated" default-period="THISMONTH" />
    </div>
    <input-select v-model="accountId" group-class="col-auto ms-auto" v-if="accounts && accounts.length > 1">
      <option value="">All Accounts</option>
      <option v-for="acct in accounts" :key="acct.accountId" :value="acct.accountId">{{acct.accountName}}</option>
    </input-select>
  </div>
  
  <div class="row mb-2">
    <div class="col-auto ms-auto"><a href="#" @click.prevent="hideZeroLine = !hideZeroLine">{{hideZeroLine ? 'Show All' : 'Hide Zero Activity/Zero Balance'}}</a></div>
  </div>
  
  <div class="row">
    <div class="col table-responsive">
    <table class="table table-bordered small">
      <thead class="table-dark">
        <tr class="text-center">
          <th width="10%">Currency</th>
          <th width="15%">Beginning<br/>Balance</th>
          <th width="15%">Pay-Ins</th>
          <th width="15%">Pay-Outs</th>
          <th width="15%">Other Credits</th>
          <th width="15%">Other Debits</th>
          <th width="15%">Ending<br/>Balance</th>
        </tr>
      </thead>
      <tbody>
        <template  v-for="bal in balances">
        <tr v-if="showRow(bal)" :key="bal.currency">
          <td class="fw-bold bg-secondary text-white text-center">{{bal.currency}}</td>
          <td class="text-end fw-bold bg-light" :class="{ 'text-danger': bal.beginningBalance < 0}"><display-amount :value="bal.beginningBalance" /></td>
          <td class="text-end text-success"><display-amount :value="bal.summaryAmounts.PAY_INS" /></td>
          <td class="text-end text-danger"><display-amount :value="bal.summaryAmounts.PAY_OUTS" /></td>
          <td class="text-end text-success"><display-amount :value="bal.summaryAmounts.ADJ_CREDITS" /></td>
          <td class="text-end text-danger"><display-amount :value="calcOtherDebits(bal.summaryAmounts)" /></td>
          <td class="text-end fw-bold bg-light" :class="{ 'text-danger': bal.endingBalance < 0}"><display-amount :value="bal.endingBalance" /></td>
        </tr>
        </template>
      </tbody>
    </table>
    </div>
  </div>
  
  <wait-modal :loading="isLoading"/>

</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import PeriodSelector from '../components/PeriodSelector.vue'
import WaitModal from '../components/WaitModal.vue'
export default {
  name: 'BalanceSummary',
  mixins: [HelpersMixin],
  components: { PeriodSelector, WaitModal },
  data () {
    return {
      initDone: false,
      accounts: [],
      accountId: 'ALL',
      periodStart: '',
      periodEnd: '',
      balances: [],
      hideZeroLine: false
    }
  },
  mounted () {
    this.getAccounts()
    this.$nextTick(function () {
        this.initDone = true
        this.getSummaries()
        this.$watch('accountId', this.getSummaries)
    })
  },
  methods: {
    getSummaries: function () {
      this.balances = []
      var qparams = { periodStart: this.periodStart, periodEnd: this.periodEnd }
      var balanceEndpoint = ''
      if (this.accountId && this.accountId !== 'ALL') {
          qparams.accountId = this.accountId
          balanceEndpoint = '/mx/accountsummaries'
      } else {
          balanceEndpoint = '/mx/balancesummaries'
      }
      this.isLoading = true
      this.axios.post(balanceEndpoint, qparams)
      .then (response => {
        this.isLoading = false
        this.balances = response.data
      })
      .catch (error => this.handleError(error))
    },
    calcOtherDebits: function (summaryAmounts) {
      if (!summaryAmounts) {
        return 0
      }
      return summaryAmounts.FEES + summaryAmounts.SETTLEMENTS + summaryAmounts.ADJ_DEBITS
    },
    onMerchantSelected: function (eventData) {
      this.merchantId = eventData.merchantId
      this.accountId = eventData.accountId
      if (this.initDone) {
          this.getSummaries()
      }
    },
    onPeriodUpdated: function (eventData) {
      this.periodStart = eventData.startDate
      this.periodEnd = eventData.endDate
      if (this.initDone) {
          this.getSummaries()
      }
    },
    getAccounts: function () {
      this.axios.get('/mx/accounts')
      .then(response => {
        this.accounts = response.data
      })
      .catch(error => this.handleError(error))
    },
    showRow: function (bal) {
      if (this.hideZeroLine && this.isAllZeroes(bal)) {
          return false
      }
      return true
    },
    isAllZeroes: function (bal) {
        if (bal.beginningBalance === 0 
                && !bal.summaryAmounts.PAY_INS 
                && !bal.summaryAmounts.PAY_OUTS 
                && !bal.summaryAmounts.ADJ_CREDITS
                && bal.endingBalance === 0) {
            return true
        }
        return false
    }
  }
}
</script>
