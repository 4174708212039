<template>
<div class="container">
  <page-title title="Transactions"/>
  
  <div class="row small mb-2">
    <b-tabs v-model="tabIndex" class="col" content-class="bg-light border border-top-0 px-2" active-nav-item-class="bg-light text-primary fw-bold">
      <b-tab title="Date Range" @click="onTabSelect('dateRange', 0)">
        <div class="row pt-2">
          <div class="col-auto">
            <period-selector @periodUpdated="onPeriodUpdated" default-period="LAST7DAYS" />
          </div>
          <div class="col-auto pt-1 ps-5 fs-6">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="dateField" value="createDate"/>
              <label class="form-check-label">Create Date</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="dateField" value="currentStatusDate"/>
              <label class="form-check-label">Status Date</label>
            </div>
          </div>
          <div class="col-auto ms-auto">
            <button class="btn btn-info btn-sm pb-2" @click="generateExportFile"><i class="fas fa-file-export" v-b-tooltip.hover title="Generate Export File"></i></button>
          </div>
        </div>
        <div class="row small bg-light mx-1 my-2">
          <div class="col-auto">
            <div class=row><div class="col-auto mb-0 mt-1 smaller">Filters:</div></div>
            <div class="row">
              <input-select v-model="txnTypeFilter" group-class="col-auto my-1" input-class="form-control-sm pe-5">
                <option value="ALL">Any Type</option>
                <option value="BD_PAY_IN">PAY-IN</option>
                <option value="BD_PAY_OUT">PAY-OUT</option>
              </input-select>
              <input-select v-model="statusFilter" group-class="col-auto my-1" input-class="form-control-sm pe-5">
                <option value="ALL">Any Status</option>
                <option value="PENDING">PENDING SUBMISSION</option>
                <option value="INPROCESS">IN PROCESS</option>
                <option value="FORAUTHZ">PENDING AUTHORIZATION</option>
                <option value="CANCELLED">CANCELLED</option>
                <option value="FAILED">FAILED</option>
                <option value="COMPLETED">COMPLETED</option>
              </input-select>
              <input-select v-model="currencyFilter" group-class="col-auto my-1" input-class="form-control-sm pe-5">
                <option value="ALL">Any Currency</option>
                <option v-for="cur in currencies" :key="cur.refCode" :value="cur.refCode">{{cur.refCode}} - {{cur.refValue}}</option>
              </input-select>
              <input-select v-model="channelCodeFilter" group-class="col-auto my-1" input-class="form-control-sm pe-5">
                <option value="ALL">All Channels</option>
                <option v-for="ch in channelCodes" :key="ch" :value="ch">{{ch}}</option>
              </input-select>
              <input-select v-model="accountIdFilter" group-class="col-auto my-1" input-class="form-control-sm pe-5" v-if="accounts && accounts.length > 1">
                <option value="ALL">All Accounts</option>
                <option v-for="acct in accounts" :key="acct.accountId" :value="acct.accountId">{{acct.accountName}}</option>
              </input-select>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Txn ID" @click="onTabSelect('txnId', 1)">
        <div class="row input-group">
          <input-text v-model="searchInput" group-class="col-md-4 pt-3 ms-3" placeholder="Transaction ID"></input-text>
          <button class="btn btn-primary btn-sm ms-2 mt-3 mb-3 col col-lg-auto mr-auto" @click="search()">Go</button>
        </div>
      </b-tab>
      <b-tab title="Payment Reference" @click="onTabSelect('paymentRef', 2)">
        <div class="row input-group">
          <input-text v-model="searchInput" group-class="col-md-4 pt-3 ms-3" placeholder="Payment Reference ID"></input-text>
          <button class="btn btn-primary btn-sm ms-2 mt-3 mb-3 col col-lg-auto mr-auto" @click="search()">Go</button>
        </div>
      </b-tab>
    </b-tabs>
  </div>
  
  <paginated-list :result="result" :search-params="searchParams" :search-fxn="search">
    <table class="col-12 table table-striped table-hover small table-responsive-sm">
      <thead class="table-dark">
        <tr>
          <th>Txn ID</th>
          <th>Create Date</th>
          <th>Status Date</th>
          <th>Account ID</th>
          <th>Ref ID</th>
          <th>Channel</th>
          <th>&nbsp;</th>
          <th class="text-center">Requested</th>
          <th class="text-center">Processed</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!result || !result.list || result.list.length === 0">
          <td colspan="10" class="text-warning text-center py-3">No Transactions Found</td>
        </tr>
        <tr v-for="(txn, index) in result.list" :key="txn.txnId" @click="showDetails(txn.txnId, index)" style="cursor: pointer;">
          <td class="text-nowrap">{{txn.txnId}}</td>
          <td class="text-nowrap small">
            <display-date :value="txn.createDate" timezone="UTC" hideyear/><br/>
            <span class="text-info"><display-date :value="txn.createDate" hideyear/></span>
          </td>
          <td class="text-nowrap small">
            <display-date :value="txn.currentStatusDate" timezone="UTC" hideyear/><br/>
            <span class="text-info"><display-date :value="txn.currentStatusDate" hideyear/></span>
          </td>
          <td>{{txn.accountId}}</td>
          <td><span class="d-inline-block text-truncate" style="max-width: 7em;">{{txn.paymentRef}}</span></td>
          <td>{{txn.channelCode}}</td>
          <td v-if="txn.txnType === 'BD_PAY_IN'" class="text-nowrap payin-col"><span class="badge bg-success">PAY-IN</span></td>
          <td v-else-if="txn.txnType === 'BD_PAY_OUT'" class="text-nowrap"><span class="badge bg-warning">PAY-OUT</span></td>
          <td v-else class="text-nowrap">{{txn.txnType}}</td>
          <td class="text-end text-nowrap"><display-amount-obj :value="txn.txnAmount"/></td>
          <td class="text-end text-nowrap"><display-amount-obj :value="txn.processedAmount"/></td>
          <td>{{txn.currentStatus}}<br/>
            <small class="text-info text-truncate d-inline-block" style="max-width: 10em;">{{ txn.txnResultCode }}</small>
          </td>
        </tr>
      </tbody>
    </table>
  </paginated-list>

  <wait-modal :loading="isLoading"/>
  
  <b-modal id="txn-details" size="lg" hide-footer title="Transaction Details" header-bg-variant="info" @hidden="onTxnDetailClosed">
    <txn-details :txn-id="selectedTxnId" @dtlChanged="onDetailChanged"/>
  </b-modal>

  <file-exporter ref="txnlistexport"
    apiGenerate="/mx/txns/export"
    :paramsForGenerate="searchParams"
    apiRetrieve="/mx/exportfile/link/">
  </file-exporter>

</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import PeriodSelector from '../components/PeriodSelector.vue'
import PaginatedList from '../components/PaginatedList.vue'
import WaitModal from '../components/WaitModal.vue'
import TxnDetails from '../components/TxnDetails.vue'
import FileExporter from '../components/FileExporter.vue'
export default {
  name: 'TxnList',
  mixins: [HelpersMixin],
  components: { PaginatedList, WaitModal, TxnDetails, PeriodSelector, FileExporter },
  data () {
    return {
      result: {},
      searchParams: {},
      dateStart: '',
      dateEnd: '',
      searchType: 'dateRange',
      searchInput: '',
      txnTypeFilter: 'ALL',
      statusFilter: 'ALL',
      procStatusCodeFilter: 'ALL',
      currencyFilter: 'ALL',
      channelCodeFilter: 'ALL',
      accountIdFilter: 'ALL',
      selectedTxnId: '',
      selectedTxnIndex: 0,
      channelCodes: [],
      accounts: [],
      currencies: [],
      dateField: 'createDate',
      tabIndex: 0
    }
  },
  mounted () {
    this.getCurrencies()
    this.getChannelCodes()
    this.getAccounts()
    this.searchParams = { offset: 0, size: 50 }

    this.$watch('txnTypeFilter', this.applyFilter)
    this.$watch('statusFilter', this.applyFilter)
    this.$watch('currencyFilter', this.applyFilter)
    this.$watch('channelCodeFilter', this.applyFilter)
    this.$watch('accountIdFilter', this.applyFilter)
    this.$watch('dateField', this.applyFilter)
  },
  methods: {
    search: function (offset) {
      if (offset) {
        this.searchParams.offset = offset
      } else {
        this.searchParams.offset = 0
      }
      
      if (this.searchType === 'dateRange') {
        this.searchParams.rangeField = this.dateField
        this.searchParams.rangeValueFrom = this.dateStart
        this.searchParams.rangeValueTo = this.dateEnd
        this.searchParams.sortField = this.dateField
        this.searchParams.criteria = []
        if (this.txnTypeFilter && this.txnTypeFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'txnType', value: this.txnTypeFilter, matchType: 'TERM' })
        }
        if (this.statusFilter && this.statusFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'currentStatus', value: this.statusFilter, matchType: 'TERM' })
        }
        if (this.procStatusCodeFilter && this.procStatusCodeFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'procStatusCode', value: this.resultCodeFilter, matchType: 'TERM' })
        }
        if (this.currencyFilter && this.currencyFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'txnAmount.currency', value: this.currencyFilter, matchType: 'TERM' })
        }
        if (this.channelCodeFilter && this.channelCodeFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'channelCode', value: this.channelCodeFilter, matchType: 'TERM' })
        }
        if (this.accountIdFilter && this.accountIdFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'accountId', value: this.accountIdFilter, matchType: 'TERM' })
        }
      } else {
        this.searchParams.rangeField = null;
        this.searchParams.rangeValueFrom = null
        this.searchParams.rangeValueTo = null
        this.searchParams.criteria = [{fieldName: this.searchType, value: this.searchInput, matchType: 'MATCHPHRASE'}]
      }
      
      this.isLoading = true
      this.result = {}
      this.axios.post('/mx/txns/search', this.searchParams)
        .then(response => {
          this.isLoading = false
          this.result = response.data
          if (!this.result.totalRecs || this.result.totalRecs === 0) {
            this.$toasted.show('No transactions found for specified criteria')
          }
          if (this.searchType === 'dateRange') {
            this.searchParams.dateStart = this.searchParams.rangeValueFrom
            this.searchParams.dateEnd = this.searchParams.rangeValueTo
            this.$store.commit('saveSearchParams', this.searchParams)
          }
        })
        .catch(error => this.handleError(error))
    },
    onTabSelect: function (selected, idx) {
      this.tabIndex = idx
      this.searchType = selected
      this.searchInput = ''
      this.result = {}
      if (selected === 'dateRange') {
        this.searchParams = this.$store.state.searchParams
        this.search()
      } else {
        this.searchParams = { offset: 0, size: 50 }
      }
    },
    applyFilter: function () {
      this.search(0)
    },
    showDetails: function (txnId, index) {
      this.selectedTxnId = txnId
      this.selectedTxnIndex = index
      this.$bvModal.show('txn-details')
    },
    onTxnDetailClosed: function () {
      this.$bvModal.hide('txn-details')
    },
    onPeriodUpdated: function (eventData) {
      this.dateStart = eventData.startDate
      this.dateEnd = eventData.endDate
      this.search()
    },
    getChannelCodes: function () {
      this.axios.get('/mx/paymentchannelcodes')
      .then(response => {
        this.channelCodes = response.data
      })
      .catch(error => this.handleError(error))
    },
    getAccounts: function () {
      this.axios.get('/mx/accounts')
      .then(response => {
        this.accounts = response.data
      })
      .catch(error => this.handleError(error))
    },
    getCurrencies: function () {
      this.axios.get('/mx/currencies')
      .then(response => {
        this.currencies = response.data
      })
      .catch(error => this.handleError(error))
    },
    onDetailChanged: function (event) {
      this.result.list.splice(this.selectedTxnIndex, 1, event)
    },
    generateExportFile: function () {
      // need to trigger the search first to make sure the searchParams are properly populated
      this.search()
      //console.log(JSON.stringify(this.searchParams))
      this.$refs.txnlistexport.generate()
    }
  }
}
</script>