<template>
<div>
  <div class="row small mb-2 fst-italic text-info">
    <div class="col-auto me-auto">Total no. of records: {{result.totalRecs}}</div>
    <div class="col-auto">
      <span v-if="hasPrev()">
        <a class="me-2" v-on:click.prevent="showFirst()" href="#"><i class="fas fa-angle-double-left" title="first page"></i></a>
        <a class="me-2" v-on:click.prevent="showPrev()" href="#"><i class="fas fa-angle-left" title="previous page"></i></a>
      </span>
      <span>Page {{result.currentPage}} of {{result.totalPages}}</span>
      <span v-if="hasNext()">
        <a class="ms-2" v-on:click.prevent="showNext()" href="#"><i class="fas fa-angle-right" title="next page"></i></a>
        <a class="ms-2" v-on:click.prevent="showLast()" href="#"><i class="fas fa-angle-double-right" title="last page"></i></a>
      </span>
      <span v-else class="ms-4 ps-1">&nbsp;</span>
      <a href="#" @click.prevent="refreshList" class="me-2 ms-3 text-info" v-b-tooltip.hover title="Refresh"><i class="fas fa-sync"></i></a>
    </div>
  </div>
  <slot></slot>
</div>
</template>

<script>
export default {
  name: 'PaginatedList',
  props: ['result', 'searchParams', 'searchFxn'],
  methods: {
    hasPrev: function () {
      return this.result.currentPage > 1
    },
    hasNext: function () {
      return this.result.currentPage < this.result.totalPages
    },
    showPrev: function () {
      const offset = this.searchParams.offset - this.searchParams.size
      this.searchFxn(offset)
    },
    showNext: function () {
      const offset = this.searchParams.offset + this.searchParams.size
      this.searchFxn(offset)
    },
    showFirst: function () {
      this.searchFxn(0)
    },
    showLast: function () {
      const offset = (this.result.totalPages - 1) * this.searchParams.size
      this.searchFxn(offset)
    },
    refreshList: function () {
      this.searchFxn()
    }
  }
}
</script>
