import Vue from 'vue'
import VueRouter from 'vue-router'
import MerchantLogin from '@/views/MerchantLogin.vue'
import PwChange from './views/PwChange.vue'
import DashboardView from "@/views/DashboardView"
import TxnList from "@/views/TxnList"
import PaymentChannels from "@/views/PaymentChannels"
import BalanceSummary from "@/views/BalanceSummary"
import store from './store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/transactions',
    name: 'txnlist',
    component: TxnList
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/balancesummary',
    name: 'balancesummary',
    component: BalanceSummary
  },
  {
    path: '/paymentchannels',
    name: 'paymentchannels',
    component: PaymentChannels
  },
  {
    path: '/pwchange',
    name: 'pwchange',
    component: PwChange
  },
  {
    path: '/login',
    name: 'login',
    component: MerchantLogin
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next()
  } else {
    if (store.state.token && store.state.token.tokenId) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
